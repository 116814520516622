'use client';

import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { TextProps } from 'antd/es/typography/Text';
import { fontVariants } from '@shared/constants/fontVariants';

const Paragraph = styled(Typography.Paragraph, {
  shouldForwardProp: (propName) => propName !== 'disableStyles',
})(({ disableStyles }: { disableStyles?: boolean }) =>
  disableStyles
    ? {
        [`& > a.${prefixCls}-typography-expand`]: {
          color: 'rgba(0, 0, 0, 0.45) !important',
        },
      }
    : {
        overflow: 'hidden',
        WebkitLineClamp: '5',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        [`& > a.${prefixCls}-typography-expand`]: {
          color: 'rgba(0, 0, 0, 0.45) !important',
        },
      },
);

const ParagraphContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  [`& > div.${prefixCls}-typography`]: { margin: 0 },
}));

export const BioParagraph: React.FC<{
  bio: string;
  disableStyles?: boolean;
  ellipsis: TextProps['ellipsis'];
  onExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const { bio, ellipsis, onExpanded, disableStyles } = props;

  return (
    <ParagraphContainer>
      <Paragraph
        ellipsis={ellipsis}
        className={fontVariants.opt3}
        disableStyles={disableStyles}
        onClick={() => onExpanded(true)}
      >
        {bio}
      </Paragraph>
    </ParagraphContainer>
  );
};
