'use client';

import React from 'react';
import { BioParagraph } from './bio';
import { ProfileName } from './profile-name';
import { SocialLinks } from './social-links';
import { ProfileCard } from './profile-card';
import { Builder } from '@shared/types/builder';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { CountryHandle } from './country-handle';
import { CTAButton } from './profile-cta-button';
import { PoweredBy } from '@shared/ui/powered-by';
import fallback from '@shared/icons/fallback.png';
import { TotalFollowers } from './total-followers';
import { Categories } from '@shared/types/categories';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ProfileCardBody } from './profile-card-body';
import { MediakitCategories } from './mediakit-categories';
import { selectPreviewMode, useSelector } from '@shared/redux';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { CreateYourMediaKit } from '@widgets/create-your-media-kit';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import { CreateMediaKitContainerDesktop } from './create-media-kit-container';
import {
  blurDataURL,
  defaultEndColor,
  defaultStartColor,
} from '@shared/constants';
import {
  profileAvatarShapes,
  profileAvatarStyles,
  profileSizes,
} from '@shared/constants/profileSizes';
import {
  getImageStyles,
  ImageWrapper,
  ProfileAvatarContainer,
} from '@widgets/mediakit/MediaKit/Profile/components/ProfileAvatar/styled';
import styled from '@emotion/styled';

let tagStyles: React.CSSProperties = {
  margin: 0,
  height: 32,
  border: 'none',
  display: 'flex',
  fontSize: '1.4rem',
  alignItems: 'center',
  borderRadius: '0.8rem',
  color: defaultEndColor,
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
};

const More = styled('span')({
  color: 'rgba(0, 0, 0, 0.45)',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.75)',
  },
});

export function Profile({
  user,
  theme,
  visits,
  mediakit,
  isPreview,
  categories,
}: {
  theme: any;
  visits: any;
  isPreview?: boolean;
  categories: Categories.List;
  user?: { vm_verify: number };
  mediakit: Builder.Mediakit.Mediakit;
}) {
  const matches = useBreakpoint(768);
  const [match, setMatch] = React.useState(false);
  const previewMode = useSelector(selectPreviewMode);
  const [disableStyles, setDisableStyles] = React.useState<boolean>(false);

  const [expanded, onExpanded] = React.useState(false);

  React.useEffect(() => {
    setDisableStyles(true);
  }, []);

  React.useEffect(() => {
    setMatch(matches);
  }, [matches]);

  const profile = mediakit?.content?.profile;
  const name = profile?.name?.value || '';
  const avatarShape = profile?.avatar_shape || profileAvatarShapes.circle;

  const styles = profileAvatarStyles[avatarShape];
  const activeSize = `${styles.width}${styles.height}` as profileSizes;

  const isMobile = previewMode === 'mobile' && isPreview;
  const primaryColor = theme?.primary_color || defaultStartColor;
  const socialLinks = mediakit.content?.profile?.social_links || [];

  const { r, g, b } = hexToRgb(primaryColor);
  const textColor = textContrastChecker(r, g, b);

  let fill: string;

  if (textColor === '#ffffff') {
    fill = primaryColor;

    tagStyles = {
      ...tagStyles,
      color: primaryColor,
    };
  } else {
    fill = '#000000';

    tagStyles = {
      ...tagStyles,
      color: '#000000',
    };
  }

  const ellipsis = {
    expanded,
    expandable: true,
    rows: match ? 3 : 7,
    symbol: <More>more</More>,
    onExpand: (_e: any, info: any) => {
      onExpanded(info.expanded);
    },
  };

  return (
    <ProfileCard isMobile={isMobile}>
      <ProfileCardBody activeSize={activeSize}>
        <ProfileName name={name} user={user} />
        <CountryHandle
          fill={fill}
          handle={mediakit.handle}
          location={mediakit.location}
        />
        <SocialLinks socialLinks={socialLinks} textColor={tagStyles.color!} />
        <TotalFollowers totalFollowers={profile?.total_followers} />
        <MediakitCategories
          mediakit={mediakit}
          tagStyles={tagStyles}
          categories={categories}
        />
        <BioParagraph
          bio={mediakit.bio}
          ellipsis={ellipsis}
          disableStyles={disableStyles}
          onExpanded={onExpanded}
        />
        <CTAButton
          theme={theme}
          mediakit={mediakit}
          isPreview={isPreview}
          buttonTextColor={textColor}
        />
        <PoweredBy />
      </ProfileCardBody>
      <CreateMediaKitContainerDesktop>
        <CreateYourMediaKit
          theme={theme}
          visits={visits}
          username={mediakit.handle}
        />
      </CreateMediaKitContainerDesktop>
      <ProfileAvatarContainer>
        <ImageWrapper>
          <ImageWithFallback
            quality={100}
            placeholder="blur"
            fallbackSrc={fallback}
            crossOrigin="anonymous"
            blurDataURL={blurDataURL}
            alt={mediakit?.handle || ''}
            width={styles.width as number}
            height={styles.height as number}
            src={mediakit?.avatar || fallback}
            style={getImageStyles(false, styles)}
          />
        </ImageWrapper>
      </ProfileAvatarContainer>
    </ProfileCard>
  );
}
